import { GraphCalendarEvents } from '@/types/GraphCalendarEvents';
import { queryGraphApi } from '@/utility/queryGraphApi';

export type GetCalendarDayEventsProps = {
  eventStartDate: Date;
  eventEndDate: Date;
};

export const getCalendarEvents = ({ eventStartDate, eventEndDate }: GetCalendarDayEventsProps) => {
  const startDate = new Date(eventStartDate);
  startDate.setUTCHours(0, 0, 0, 0); // Set to midnight (00:00:00.000) in UTC

  const endDate = new Date(eventEndDate);
  endDate.setUTCHours(23, 59, 59, 999); // Set to the last millisecond (23:59:59.999) in UTC

  return queryGraphApi<GraphCalendarEvents>({
    method: 'GET',
    path: `/v1.0/me/calendarview?startdatetime=${startDate.toISOString()}&enddatetime=${endDate.toISOString()}&top=${import.meta.env.VITE_CALENDAR_EVENT_MAXCOUNT}`,
  });
};
