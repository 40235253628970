import { createFileRoute } from '@tanstack/react-router';

import { HarvestImport } from '@/components/HarvestImport.tsx';
import { OutlookImport } from '@/components/OutlookImport.tsx';
import { getHarvestAccessToken } from '@/utility/getHarvestAccessToken';

// Not using a lazy-loaded route to be able to use `beforeLoad` here
export const Route = createFileRoute('/import')({
  beforeLoad: () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('source') === 'harvest') {
      return getHarvestAccessToken();
    }
  },
  component: () => {
    const searchParams = new URLSearchParams(window.location.search);
    let module: JSX.Element | null;
    switch (searchParams.get('source')) {
      case 'harvest':
        module = <HarvestImport />;
        break;
      case 'outlook':
        module = <OutlookImport />;
        break;
      default:
        module = <HarvestImport />;
        break;
    }
    return module;
  },
});
