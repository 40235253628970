import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons';
import { useAtom } from 'jotai';

import { Button } from '@/components/ui/button';
import { requestedPayPeriodAtom } from '@/config/jotai.ts';
import { cn } from '@/lib/utils.ts';
import {
  getNextRequestedPayPeriod,
  getPreviousRequestedPayPeriod,
  longDateString,
  shortDateString,
} from '@/utility/dateUtils';

export const PayPeriodSelector = ({ className }: React.HTMLAttributes<HTMLDivElement>) => {
  const [requestedPayPeriod, setRequestedPayPeriod] = useAtom(requestedPayPeriodAtom);

  return (
    <div className={cn('flex items-center justify-center gap-0.5 p-2', className)}>
      <Button
        size="sm"
        className="sm:rounded-r-none"
        onClick={() =>
          setRequestedPayPeriod(getPreviousRequestedPayPeriod(requestedPayPeriod.beginDate))
        }
      >
        <ArrowLeftIcon className="h-5 w-5 sm:h-7 sm:w-7" />
      </Button>
      <Button
        className="hidden rounded-l-none sm:inline-flex"
        size="sm"
        onClick={() =>
          setRequestedPayPeriod(getNextRequestedPayPeriod(requestedPayPeriod.beginDate))
        }
      >
        <ArrowRightIcon className="h-7 w-7" />
      </Button>
      <p className="pl-4 text-center text-lg xsonly:hidden">
        {longDateString(requestedPayPeriod.beginDate)} to{' '}
        {longDateString(requestedPayPeriod.endDate)}
      </p>
      <p className="w-48 text-center text-sm leading-7 sm:hidden">
        {shortDateString(requestedPayPeriod.beginDate)} to{' '}
        {shortDateString(requestedPayPeriod.endDate)}
      </p>
      <Button
        className="sm:hidden"
        size="sm"
        onClick={() =>
          setRequestedPayPeriod(getNextRequestedPayPeriod(requestedPayPeriod.beginDate))
        }
      >
        <ArrowRightIcon className="h-5 w-5" />
      </Button>
    </div>
  );
};
