import { IPublicClientApplication } from '@azure/msal-browser';

import { graphLoginRequest } from '../config/msal';

export const getGraphAccessToken = async (
  msalInstance: IPublicClientApplication
): Promise<string> => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    throw new Error('User is not signed in');
  }

  const request = {
    ...graphLoginRequest,
    account: activeAccount || accounts[0],
  };

  const authResult = await msalInstance.acquireTokenSilent(request).catch((err) => {
    if (err.name === 'InteractionRequiredAuthError') {
      console.warn('Silent token acquisition failed, acquiring token using redirect');
      // Fallback to an interactive method
      msalInstance
        .acquireTokenRedirect(request)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw new Error(`Silent and interactive token acquisition failed with error: ${err}`);
        });
    }
  });

  return authResult!.accessToken;
};
