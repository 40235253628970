import { zodResolver } from '@hookform/resolvers/zod';
import { CalendarIcon, Pencil1Icon } from '@radix-ui/react-icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { BaseSyntheticEvent, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  addExternalTimeSheetMapping,
  AddExternalTimeSheetMappingProps,
} from '@/api/addExternalTimeSheetMapping.ts';
import {
  editExternalTimeSheetMapping,
  EditExternalTimeSheetMappingProps,
} from '@/api/editExternalTimeSheetMapping.ts';
import { Button } from '@/components/ui/button.tsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog.tsx';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { toast } from '@/components/ui/use-toast.ts';
import { requestedPayPeriodAtom } from '@/config/jotai';
import { cn, QueryKeys } from '@/lib/utils.ts';
import {
  ExternalTimeSheetMapping,
  ExternalTimeSheetSource,
} from '@/types/ExternalTimeSheetMapping.ts';
import { Project } from '@/types/Project.ts';
import { ExternalTimeSheetEntry } from '@/types/TimeSheetEntry.ts';
import {
  setCategoryIsValid,
  timeSheetEntryEditSchema,
  TimeSheetEntryEditValues,
} from '@/types/TimeSheetEntryFormValues.ts';

import { Icons } from './icons';
import { TimeSheetEntryForm } from './TimeSheetEntryForm';
import { Calendar } from './ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';

type EditExternalTimeEntryButtonProps = {
  externalTimeSheetEntry: ExternalTimeSheetEntry;
  externalTimeSheetSource: ExternalTimeSheetSource;
  onMappingSave: (externalTimeEntryId: string, timeEntry?: ExternalTimeSheetEntry) => void;
  projects: Project[];
  saveTimeEntry: (timeEntry: ExternalTimeSheetEntry) => void;
  editTimeSheetEntryPending: boolean | undefined;
};

export const EditExternalTimeEntryButton = ({
  externalTimeSheetEntry,
  externalTimeSheetSource,
  onMappingSave,
  projects,
  saveTimeEntry,
  editTimeSheetEntryPending,
}: EditExternalTimeEntryButtonProps) => {
  const saveAndDefaultName = 'save-and-default';

  const queryClient = useQueryClient();
  const [showDialog, setShowDialog] = useState(false);
  const [openCalendarForm, setOpenCalendarForm] = useState(false);
  const requestedPayPeriod = useAtomValue(requestedPayPeriodAtom);

  const defaultValues: TimeSheetEntryEditValues = {
    category: externalTimeSheetEntry.categoryId,
    date: new Date(externalTimeSheetEntry.entryDate),
    description: externalTimeSheetEntry.description,
    hours: externalTimeSheetEntry.hours,
    project: externalTimeSheetEntry.stintId,
    task: externalTimeSheetEntry.taskId,
  };

  const form = useForm<TimeSheetEntryEditValues>({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(timeSheetEntryEditSchema),
  });

  const { mutate: editMappingMutate, isPending: editMappingPending } = useMutation({
    mutationFn: ({ externalTimeSheetMapping }: EditExternalTimeSheetMappingProps) =>
      editExternalTimeSheetMapping({
        externalTimeSheetMapping,
      }),
    onError: (error) => {
      toast({
        description: error.message,
        title: error.name,
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      toast({
        description: 'Successfully edited time sheet mapping',
        title: 'Edit Time Sheet Mapping',
      });
      // Refresh the list of time sheet entries
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ExternalTimeSheetMappings] });
      setShowDialog(false);
    },
  });

  const { mutate: addMappingMutate, isPending: addMappingPending } = useMutation({
    mutationFn: ({ externalTimeSheetMapping }: AddExternalTimeSheetMappingProps) =>
      addExternalTimeSheetMapping({
        externalTimeSheetMapping,
      }),
    onError: (error) => {
      toast({
        description: error.message,
        title: error.name,
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      toast({
        description: 'Successfully added time sheet mapping',
        title: 'Add Time Sheet Mapping',
      });
      // Refresh the list of time sheet entries
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ExternalTimeSheetMappings] });
      setShowDialog(false);
    },
  });

  const saveMapping = (data: TimeSheetEntryEditValues) => {
    const projectId = projects.find((p) => p.stintId === data.project)?.id ?? '';
    const externalTimeSheetMapping: ExternalTimeSheetMapping = {
      id: externalTimeSheetEntry.mappingId,
      source: externalTimeSheetSource,
      projectId: projectId,
      taskId: data.task ?? '',
      categoryId: data.category,
      externalProject: externalTimeSheetEntry.externalProjectId,
      externalProjectDescription: externalTimeSheetEntry.externalProjectName,
      externalTask: externalTimeSheetEntry.externalTaskId,
      externalTaskDescription: externalTimeSheetEntry.externalTaskName,
    };

    if (externalTimeSheetEntry.mappingId) {
      editMappingMutate({
        externalTimeSheetMapping,
      });
    } else {
      addMappingMutate({
        externalTimeSheetMapping,
      });
    }
  };

  const submitForm = (data: TimeSheetEntryEditValues, event: BaseSyntheticEvent | undefined) => {
    const categoryIsValid = setCategoryIsValid(
      projects,
      data.project,
      data.category,
      form.clearErrors,
      form.setError
    );
    if (!categoryIsValid) {
      return;
    }

    const formEvent = event?.nativeEvent as SubmitEvent;
    const button = formEvent.submitter as HTMLButtonElement;
    const project = projects.find((p) => p.stintId === data.project);
    const task = project?.tasks.find((t) => t.id === data.task);
    const category = project?.categories.find((c) => c.id === data.category);
    const timeEntry: ExternalTimeSheetEntry = {
      ...externalTimeSheetEntry,
      stintId: project?.stintId ?? '',
      projectId: project?.id ?? '',
      taskId: task?.id ?? '',
      categoryId: category?.id,
      usesDefaultProjectOrTask: false,
      projectName: project?.displayName ?? '',
      taskName: task?.displayName ?? '',
      categoryName: category?.displayName,
      entryDate: data.date.toLocaleDateString('en-US'),
      description: data.description,
      hours: data.hours,
    };

    if (button.name === saveAndDefaultName) {
      if (externalTimeSheetSource === 'Harvest') {
        saveMapping(data);
      }
      onMappingSave(externalTimeSheetEntry.externalId, timeEntry);
    } else {
      saveTimeEntry(timeEntry);
    }
  };

  function getCalendarDisplayValue(date: Date): string {
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
    });
  }

  const calendarFormField = (
    <FormField
      control={form.control}
      name="date"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>Date</FormLabel>
          <Popover open={openCalendarForm} onOpenChange={setOpenCalendarForm}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  className={cn(
                    'pl-3 text-left font-normal',
                    !field.value && 'text-muted-foreground'
                  )}
                  disabled={editTimeSheetEntryPending || addMappingPending || editMappingPending}
                >
                  <span>{getCalendarDisplayValue(field.value)}</span>
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={field.value}
                onSelect={field.onChange}
                onDayClick={() => setOpenCalendarForm(false)}
                disabled={(date) =>
                  date > requestedPayPeriod.endDate || date < requestedPayPeriod.beginDate
                }
                defaultMonth={requestedPayPeriod.beginDate}
                required
              />
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  return (
    <Dialog open={showDialog} onOpenChange={setShowDialog}>
      <DialogTrigger asChild>
        <Button title="Edit">
          <Pencil1Icon></Pencil1Icon>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Map {externalTimeSheetSource} Entry to Dashboard</DialogTitle>
          <DialogDescription>
            Save for this entry only or set as default for{' '}
            {externalTimeSheetSource === 'Outlook'
              ? 'related Oulook entries.'
              : `this ${externalTimeSheetSource} project/task combo.`}
          </DialogDescription>
        </DialogHeader>
        {externalTimeSheetSource !== 'Outlook' ? (
          <>
            <h2 className="font-semibold">{externalTimeSheetSource}</h2>
            <p className="text-sm">Project: {externalTimeSheetEntry.externalProjectName}</p>
            <p className="text-sm">Task: {externalTimeSheetEntry.externalTaskName}</p>
            <h2 className="font-semibold">Dashboard</h2>
          </>
        ) : (
          ''
        )}

        <Form {...form}>
          <form
            id="external-time-entry-edit"
            onSubmit={form.handleSubmit(submitForm)}
            className="flex w-full flex-col gap-3"
          >
            <TimeSheetEntryForm
              calendarFormField={calendarFormField}
              defaultValues={defaultValues}
              projects={projects}
              submitPending={editTimeSheetEntryPending || addMappingPending || editMappingPending}
            />
          </form>
        </Form>
        <DialogFooter>
          <div className="flex flex-col-reverse gap-3 sm:flex-row sm:justify-between">
            <Button
              form="external-time-entry-edit"
              name="save"
              type="submit"
              disabled={editTimeSheetEntryPending || addMappingPending || editMappingPending}
            >
              Save
            </Button>
            <Button
              form="external-time-entry-edit"
              name={saveAndDefaultName}
              type="submit"
              disabled={editTimeSheetEntryPending || addMappingPending || editMappingPending}
            >
              {editTimeSheetEntryPending || addMappingPending || editMappingPending ? (
                <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                ''
              )}{' '}
              Save & Default
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
