import { msalInstance } from '@/main';

import { getAccessToken } from './getAccessToken';

type Arguments = {
  path: string;
  body?: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
};

export const queryApi = async <T>({ path, body, method }: Arguments): Promise<T | undefined> => {
  // TODO: replace with a constant or at least higher up, not in this function
  const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;

  const accessToken = await getAccessToken(msalInstance);

  const httpHeaders: HeadersInit = {
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  // Hit the API
  const response = await fetch(`${apiBaseUrl}${path}`, {
    body,
    headers: httpHeaders,
    method,
  });

  if (!response.ok) {
    const defaultErrorMessage = `Unable to query ${apiBaseUrl}${path}.`;
    const errorResponse = await response.json();
    throw new Error(
      `${errorResponse.Detail || defaultErrorMessage} Error code: ${response.status}`
    );
  }

  // Don't return results if status is No Content
  if (response.status !== 204) return response.json();
};
