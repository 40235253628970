import { PropsWithChildren, useEffect } from 'react';

function ScrollableContainer({ children }: PropsWithChildren) {
  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if ((e.target as Element).closest('[data-scrollable]')) return;
      e.stopPropagation();
    };

    const handleTouchMove = (e: TouchEvent) => {
      if ((e.target as Element).closest('[data-scrollable]')) return;
      e.stopPropagation();
    };

    document.addEventListener('wheel', handleWheel, true);
    document.addEventListener('touchmove', handleTouchMove, true);

    return () => {
      document.removeEventListener('wheel', handleWheel, true);
      document.removeEventListener('touchmove', handleTouchMove, true);
    };
  }, []);
  return (
    <div className="scrollbar relative max-h-72 overflow-hidden overflow-y-auto">{children}</div>
  );
}

export default ScrollableContainer;
