import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import { addTimeSheetEntriesV2, AddTimeSheetEntriesV2Props } from '@/api/addTimeSheetEntriesV2';
import { getCalendarEvents } from '@/api/getCalendarDayEvents';
import { getTimeSheetPayload } from '@/api/getTimeSheetPayload';
import { PayPeriodSelector } from '@/components/PayPeriodSelector.tsx';
import { toast } from '@/components/ui/use-toast.ts';
import { payPeriodCalenderEventsSubmittedAtom, requestedPayPeriodAtom } from '@/config/jotai';
import { QueryKeys } from '@/lib/utils';
import { CalendarEvent } from '@/types/CalendarEvent';
import { GraphCalendarEvents } from '@/types/GraphCalendarEvents';
import { ExternalTimeSheetEntry } from '@/types/TimeSheetEntry';
import { TimeSheetPayload } from '@/types/TimeSheetPayload';
import { getDatesInRange } from '@/utility/dateUtils';

import { EgSpinner } from './EgSpinner';
import { getExternalTimeEntryColumns } from './ExternalTimeEntryColumns';
import { Button } from './ui/button';
import { DataTable } from './ui/data-table';

export const OutlookImport = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const [payPeriodCalenderEventsSubmitted, setPayPeriodCalenderEventsSubmitted] = useAtom(
    payPeriodCalenderEventsSubmittedAtom
  );
  const requestedPayPeriod = useAtomValue(requestedPayPeriodAtom);
  const [hasCalendarEvents, setHasCalendarEvents] = useState(false);
  const [editTimeSheetEntryPending, setEditTimeSheetEntryPending] = useState(false);
  const [timeEntries, setTimeEntries] = useState<ExternalTimeSheetEntry[]>([]);

  const {
    data: graphCalendarEvents,
    isLoading: isGraphCalnderEventsLoading,
  }: UseQueryResult<GraphCalendarEvents> = useQuery({
    queryFn: () =>
      getCalendarEvents({
        eventEndDate: requestedPayPeriod.endDate,
        eventStartDate: requestedPayPeriod.beginDate,
      }),
    queryKey: [QueryKeys.CalendarEvents, requestedPayPeriod.beginDate, requestedPayPeriod.endDate],
  });

  const {
    data: timeSheetPayload,
    isLoading: isTimeSheetPayloadLoading,
  }: UseQueryResult<TimeSheetPayload> = useQuery({
    queryFn: () => getTimeSheetPayload({ dateInPayPeriod: requestedPayPeriod.beginDate }),
    queryKey: [QueryKeys.TimeSheetPayload, requestedPayPeriod.beginDate],
  });

  const hasCalendarEventsSubmitted =
    payPeriodCalenderEventsSubmitted.get(requestedPayPeriod.beginDate.toString()) ?? false;

  const convertUtctoEst = (utcDate: string | Date, type: 'date' | 'time'): string => {
    // Create a Date object from the input UTC date
    const date = new Date(utcDate);

    // Define base options for the EST timezone
    const baseOptions: Intl.DateTimeFormatOptions = {
      timeZone: 'America/New_York',
    };

    // Adjust options based on the type parameter
    const options: Intl.DateTimeFormatOptions =
      type === 'date'
        ? { ...baseOptions, year: 'numeric', month: '2-digit', day: '2-digit' }
        : { ...baseOptions, hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };

    // Use Intl.DateTimeFormat to format the date in the 'America/New_York' timezone
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  useEffect(() => {
    if (graphCalendarEvents && timeSheetPayload && !hasCalendarEventsSubmitted) {
      setHasCalendarEvents(true);
      //Get normal calendar events
      const outlookEvents: CalendarEvent[] = graphCalendarEvents.value
        .filter((x) => !x.isAllDay)
        .map((event) => {
          return {
            bodyPreview: event.subject,
            date: convertUtctoEst(`${event.start.dateTime}Z`, 'date'),
            hours: event.isAllDay
              ? 8
              : (new Date(event.end.dateTime).getTime() -
                  new Date(event.start.dateTime).getTime()) /
                (1000 * 60 * 60),
            iCalUId: event.iCalUId,
            uid: event.uid,
            isSelected: false,
            subject: `${event.subject} from ${convertUtctoEst(`${event.start.dateTime}Z`, 'time')} to ${convertUtctoEst(
              `${event.end.dateTime}Z`,
              'time'
            )}`,
          };
        });

      //Get allday calendar events
      const allDayCalendarEvents = graphCalendarEvents.value.filter((x) => x.isAllDay);

      allDayCalendarEvents.forEach((event) => {
        const calendarDates = getDatesInRange(
          new Date(event.start.dateTime),
          new Date(new Date(event.end.dateTime).getTime() - 1000)
        );

        calendarDates.forEach((date) => {
          outlookEvents.push({
            bodyPreview: event.subject,
            date: date.toISOString().split('T')[0],
            hours: 8,
            iCalUId: event.iCalUId,
            uid: event.uid,
            isSelected: false,
            subject: `${event.subject}. All day event.`,
          });
        });
      });

      outlookEvents.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      const project =
        timeSheetPayload.submittableProjects.find(
          (p) => p.name === import.meta.env.VITE_CALENDAR_PROJECT
        ) ?? timeSheetPayload.submittableProjects[0];

      const task =
        project?.tasks.find((t) => t.name === import.meta.env.VITE_CALENDAR_TASK) ??
        project?.tasks[0];

      const category =
        project?.categories.find((c) => c.name === import.meta.env.VITE_CALENDAR_CATEGORY) ??
        project?.categories[0];

      let tempTimeEntries: ExternalTimeSheetEntry[] = [];
      tempTimeEntries = outlookEvents.map((calendarEvent) => {
        return {
          stintId: project?.stintId ?? '',
          projectId: project?.id,
          taskId: task?.id ?? '',
          categoryId: category?.id,
          description: calendarEvent.subject,
          entryDate: calendarEvent.date,
          hours: calendarEvent.hours,
          usesDefaultProjectOrTask: false,
          projectName: project?.displayName ?? '',
          taskName: task?.displayName ?? '',
          categoryName: category?.displayName,
          externalId: calendarEvent.iCalUId,
          externalLinkedId: calendarEvent.uid,
        };
      });
      setTimeEntries(tempTimeEntries);
    }
  }, [graphCalendarEvents, timeSheetPayload, hasCalendarEventsSubmitted]);

  const projects =
    timeSheetPayload?.submittableProjects.map((project) => {
      project.tasks = project.tasks.map((task) => {
        // Allow the task name portion that comes after the colon to wrap.
        return { ...task, displayName: task.displayName.replace(':', ':\u200B') };
      });
      return project;
    }) ?? [];

  const removeOutlookEntry = (externalId: string) => {
    const filteredTimeEntries = timeEntries.filter((entry) => entry.externalId !== externalId);
    setTimeEntries(filteredTimeEntries);
  };

  const saveAndDefaultRelatedOutlookEntries = (
    externalTimeEntryId: string,
    timeEntry?: ExternalTimeSheetEntry
  ) => {
    setEditTimeSheetEntryPending(true);

    const updatedTimeEntries = timeEntries.map((entry) => {
      if (entry.externalLinkedId === timeEntry?.externalLinkedId) {
        return {
          ...entry,
          stintId: timeEntry?.stintId,
          projectId: timeEntry?.projectId,
          projectName: timeEntry?.projectName,
          taskId: timeEntry?.taskId,
          taskName: timeEntry?.taskName,
          categoryId: timeEntry?.categoryId,
          categoryName: timeEntry?.categoryName,
          entryDate:
            entry.externalId === externalTimeEntryId ? timeEntry?.entryDate : entry.entryDate,
          description: timeEntry?.description,
          hours: timeEntry?.hours,
        };
      }
      return entry;
    });

    setTimeEntries(
      updatedTimeEntries.filter((entry): entry is ExternalTimeSheetEntry => entry !== undefined)
    );

    setEditTimeSheetEntryPending(false);
  };

  const saveTimeEntry = (updatedTimeEntry: ExternalTimeSheetEntry) => {
    setEditTimeSheetEntryPending(true);
    const oldEntry = timeEntries.find((t) => t.externalId === updatedTimeEntry.externalId);
    if (oldEntry) {
      Object.assign(oldEntry, updatedTimeEntry);
      setTimeEntries([...timeEntries]);
    } else {
      setTimeEntries([...timeEntries, updatedTimeEntry]);
    }
    setEditTimeSheetEntryPending(false);
  };

  const { mutate: addTimeSheetEntriesMutate, isPending: isSubmitTimeEntries } = useMutation({
    mutationFn: ({ timeSheetEntries }: AddTimeSheetEntriesV2Props) => {
      console.log(`Submit timeSheetEntries : ${JSON.stringify(timeSheetEntries)}`);
      return addTimeSheetEntriesV2({
        timeSheetEntries: timeSheetEntries,
      });
    },
    onError: (error) => {
      console.log(`Error : ${error.message}`);
      toast({
        description: error.message,
        title: error.name,
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      toast({
        description: 'Successfully added time sheet entries',
        title: 'Add Time Sheet Entries',
      });
      setHasCalendarEvents(false);
      payPeriodCalenderEventsSubmitted.set(requestedPayPeriod.beginDate.toString(), true);
      setPayPeriodCalenderEventsSubmitted(payPeriodCalenderEventsSubmitted);
      queryClient.setQueryData<GraphCalendarEvents>(
        [QueryKeys.CalendarEvents, requestedPayPeriod.beginDate, requestedPayPeriod.endDate],
        () => {
          return undefined;
        }
      );
      // Refresh the list of time sheet entries
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TimeSheetPayload, requestedPayPeriod.beginDate],
      });
      router.history.push(`/`);
    },
  });

  return (
    <>
      <header className="sticky top-0 z-50 w-full bg-navigation text-navigation-foreground backdrop-blur supports-[backdrop-filter]:bg-navigation-transparent/75">
        <div className="bg-navigation/95 p-2.5">
          <h1 className="mb-1 scroll-m-20 text-center text-xl font-medium tracking-tight sm:text-4xl sm:font-normal">
            Import Time Entries from Outlook
          </h1>
        </div>
        <div className="flex items-center justify-between">
          <PayPeriodSelector className="hidden px-6 sm:flex" />
          <div className="hidden sm:flex">Refresh page to reset</div>
          <div className="py-2 pl-2 pr-1 sm:pl-40 sm:pr-6">
            <Button variant="outline" onClick={() => router.history.push(`/`)}>
              Cancel
            </Button>
            <Button
              className="ml-2"
              disabled={!hasCalendarEvents || hasCalendarEventsSubmitted}
              onClick={() => addTimeSheetEntriesMutate({ timeSheetEntries: timeEntries })}
            >
              Submit Entries
            </Button>
          </div>
        </div>
      </header>
      {isGraphCalnderEventsLoading || isTimeSheetPayloadLoading || isSubmitTimeEntries ? (
        <EgSpinner />
      ) : !hasCalendarEvents || hasCalendarEventsSubmitted ? (
        <div className="ml-2 mt-2">No Outlook time entries are available to submit.</div>
      ) : (
        <div className="container mx-auto pb-12 sm:p-10">
          <DataTable
            columns={getExternalTimeEntryColumns(
              removeOutlookEntry,
              'Outlook',
              saveAndDefaultRelatedOutlookEntries,
              projects,
              saveTimeEntry,
              editTimeSheetEntryPending
            )}
            data={timeEntries}
          />
        </div>
      )}
      <footer className="fixed bottom-0 w-full border-t bg-navigation-transparent/75 text-navigation-foreground backdrop-blur sm:hidden">
        <PayPeriodSelector className="mx-auto" />
      </footer>
    </>
  );
};
