export type RequestedPayPeriod = {
  beginDate: Date;
  endDate: Date;
};

export const getCurrentRequestedPayPeriod = (): RequestedPayPeriod => {
  const today = new Date();

  return isFirstHalfOfMonth(today)
    ? {
        beginDate: new Date(today.getFullYear(), today.getMonth(), 1),
        endDate: new Date(today.getFullYear(), today.getMonth(), 15),
      }
    : {
        beginDate: new Date(today.getFullYear(), today.getMonth(), 16),
        endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0),
      };
};

export const getPreviousRequestedPayPeriod = (payPeriodDate: Date): RequestedPayPeriod => {
  const payPeriodMonth = new Date(payPeriodDate).getMonth();
  const payPeriodYear = new Date(payPeriodDate).getFullYear();

  return isFirstHalfOfMonth(payPeriodDate)
    ? {
        beginDate: new Date(payPeriodYear, payPeriodMonth - 1, 16),
        endDate: new Date(payPeriodYear, payPeriodMonth, 0),
      }
    : {
        beginDate: new Date(payPeriodYear, payPeriodMonth, 1),
        endDate: new Date(payPeriodYear, payPeriodMonth, 15),
      };
};

export const getNextRequestedPayPeriod = (payPeriodDate: Date): RequestedPayPeriod => {
  const payPeriodMonth = new Date(payPeriodDate).getMonth();
  const payPeriodYear = new Date(payPeriodDate).getFullYear();

  return isFirstHalfOfMonth(payPeriodDate)
    ? {
        beginDate: new Date(payPeriodYear, payPeriodMonth, 16),
        endDate: new Date(payPeriodYear, payPeriodMonth + 1, 0),
      }
    : {
        beginDate: new Date(payPeriodYear, payPeriodMonth + 1, 1),
        endDate: new Date(payPeriodYear, payPeriodMonth + 1, 15),
      };
};

export const longDateString = (date: Date) => date.toLocaleDateString();

export const shortDateString = (date: Date) =>
  date.toLocaleString('en-us', {
    day: 'numeric',
    month: 'numeric',
  });

/// Returns a date string YYYY-MM-DD in the format MM/DD/YYYY
export const formatDateString = (dateStr: string) => {
  const [year, month, day] = dateStr.split('-').map(Number); // Split and convert to numbers
  const date = new Date(year, month - 1, day); // Month is zero-based in JavaScript Date
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const isToday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const isWeekend = (date: Date) => {
  const dayOfWeek = date.getDay();
  return dayOfWeek === 0 || dayOfWeek === 6;
};

export const getDatesInRange = (startDate: Date, endDate: Date) => {
  const dates = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = new Date(currentDate);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

const isFirstHalfOfMonth = (date: Date): boolean => {
  const dayOfMonth = date.getDate();
  return dayOfMonth >= 1 && dayOfMonth <= 15;
};
