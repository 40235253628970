import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import { addTimeSheetEntriesV2, AddTimeSheetEntriesV2Props } from '@/api/addTimeSheetEntriesV2.ts';
import { getExternalTimeSheetMappings } from '@/api/getExternalTimeSheetMappings.ts';
import { getHarvestTimeEntries } from '@/api/getHarvestTimeEntries.ts';
import { getTimeSheetPayload } from '@/api/getTimeSheetPayload.ts';
import { EgSpinner } from '@/components/EgSpinner.tsx';
import { getExternalTimeEntryColumns } from '@/components/ExternalTimeEntryColumns.tsx';
import { PayPeriodSelector } from '@/components/PayPeriodSelector.tsx';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { DataTable } from '@/components/ui/data-table.tsx';
import { toast } from '@/components/ui/use-toast.ts';
import { requestedPayPeriodAtom } from '@/config/jotai.ts';
import { QueryKeys } from '@/lib/utils.ts';
import { HarvestTimeEntryResponse } from '@/types/HarvestTimeEntry.ts';
import { ExternalTimeSheetEntry } from '@/types/TimeSheetEntry.ts';
import { formatDateString } from '@/utility/dateUtils';

export const HarvestImport = () => {
  const defaultProjectName = 'E-g -> Internal';
  const defaultTaskName = 'Miscellaneous';

  const queryClient = useQueryClient();
  const router = useRouter();
  const [editTimeSheetEntryPending, setEditTimeSheetEntryPending] = useState(false);
  const [modifiedTimeEntries, setModifiedTimeEntries] = useState<
    Map<string, ExternalTimeSheetEntry>
  >(new Map());
  const [timeEntries, setTimeEntries] = useState<ExternalTimeSheetEntry[]>([]);
  const requestedPayPeriod = useAtomValue(requestedPayPeriodAtom);

  const { data: harvestData, isLoading: harvestIsLoading } = useQuery({
    queryFn: () =>
      getHarvestTimeEntries({ from: requestedPayPeriod.beginDate, to: requestedPayPeriod.endDate }),
    queryKey: [
      QueryKeys.HarvestTimeEntries,
      requestedPayPeriod.beginDate,
      requestedPayPeriod.endDate,
    ],
  });

  const { data: timeSheetMappings, isLoading: mappingsAreLoading } = useQuery({
    queryFn: () => getExternalTimeSheetMappings(),
    queryKey: [QueryKeys.ExternalTimeSheetMappings],
  });

  // We don't need E-g time entries on this page, so it would be ideal if this didn't pull all that data.
  const { data: timeSheetPayload, isLoading: payloadIsLoading } = useQuery({
    queryFn: () => getTimeSheetPayload({ dateInPayPeriod: requestedPayPeriod.beginDate }),
    queryKey: [QueryKeys.TimeSheetPayload, requestedPayPeriod.beginDate],
  });

  useEffect(() => {
    let tempTimeEntries: ExternalTimeSheetEntry[] = [];
    if (harvestData && timeSheetPayload) {
      tempTimeEntries = harvestData?.time_entries?.map((harvestEntry) => {
        const mapping = timeSheetMappings?.find(
          (m) =>
            m.externalProject === harvestEntry.project.id.toString() &&
            m.externalTask === harvestEntry.task.id.toString()
        );

        const mappingProject = timeSheetPayload?.submittableProjects.find(
          (p) => p.id === mapping?.projectId
        );
        const defaultProject =
          timeSheetPayload?.submittableProjects.find((p) => p.name === defaultProjectName) ??
          timeSheetPayload.submittableProjects[0];
        const project = mappingProject ?? defaultProject;

        const mappingTask = project.tasks.find((t) => t.id === mapping?.taskId);
        const defaultTask =
          project.tasks.find((t) => t.displayName === defaultTaskName) ?? project.tasks[0];
        const task = mappingTask ?? defaultTask;

        const category = project.categories.find((c) => c.id === mapping?.categoryId);

        const isModifiedTimeEntryFound = modifiedTimeEntries.has(harvestEntry.id.toString());
        if (isModifiedTimeEntryFound) {
          const modifiedEntry = modifiedTimeEntries.get(harvestEntry.id.toString())!;
          return {
            ...modifiedEntry,
            stintId: mapping ? project.stintId : modifiedEntry.stintId,
            projectId: mapping ? project.id : modifiedEntry.projectId,
            taskId: mapping ? task.id : modifiedEntry.taskId,
            categoryId: mapping ? category?.id : modifiedEntry.categoryId,
            usesDefaultProjectOrTask: !mappingProject || !mappingTask,
            projectName: mapping ? project.name : modifiedEntry.projectName,
            taskName: mapping ? task.displayName : modifiedEntry.taskName,
            categoryName: mapping ? category?.displayName : modifiedEntry.categoryName,
            mappingId: mapping ? mapping?.id : modifiedEntry.mappingId,
          };
        }
        return {
          stintId: project.stintId,
          projectId: project.id,
          taskId: task.id,
          categoryId: category?.id,
          description:
            harvestEntry.notes ??
            `Imported from Harvest. Harvest info: ${harvestEntry.client.name} - ${harvestEntry.project.name} - ${harvestEntry.task.name}`,
          entryDate: formatDateString(harvestEntry.spent_date),
          hours: harvestEntry.hours,
          usesDefaultProjectOrTask: !mappingProject || !mappingTask,
          projectName: project.name,
          taskName: task.displayName,
          categoryName: category?.displayName,
          externalId: harvestEntry.id.toString(),
          externalProjectId: harvestEntry.project.id.toString(),
          externalProjectName: harvestEntry.project.name,
          externalTaskId: harvestEntry.task.id.toString(),
          externalTaskName: harvestEntry.task.name,
          mappingId: mapping?.id,
        };
      });
      tempTimeEntries.sort(
        (a, b) => new Date(a.entryDate).getTime() - new Date(b.entryDate).getTime()
      );
    }
    setTimeEntries(tempTimeEntries);
  }, [timeSheetPayload, harvestData, timeSheetMappings, modifiedTimeEntries]);

  const projects =
    timeSheetPayload?.submittableProjects.map((project) => {
      project.tasks = project.tasks.map((task) => {
        // Allow the task name portion that comes after the colon to wrap.
        return { ...task, displayName: task.displayName.replace(':', ':\u200B') };
      });
      return project;
    }) ?? [];

  const saveHarvestEntry = (updatedTimeEntry: ExternalTimeSheetEntry) => {
    setEditTimeSheetEntryPending(true);
    if (updatedTimeEntry) {
      const isMappingFound = modifiedTimeEntries.has(updatedTimeEntry.externalId);
      if (isMappingFound) {
        modifiedTimeEntries.delete(updatedTimeEntry.externalId);
      }
      setModifiedTimeEntries(
        new Map(modifiedTimeEntries.set(updatedTimeEntry.externalId, updatedTimeEntry))
      );
    }
    setEditTimeSheetEntryPending(false);
  };

  const saveAndDefaultRelatedHarvestEntries = (
    externalTimeEntryId: string,
    updatedTimeEntry?: ExternalTimeSheetEntry
  ) => {
    setEditTimeSheetEntryPending(true);
    if (updatedTimeEntry && externalTimeEntryId) {
      saveHarvestEntry(updatedTimeEntry);
    }
    setEditTimeSheetEntryPending(false);
  };

  const removeHarvestEntry = (externalId: string) => {
    setEditTimeSheetEntryPending(true);
    queryClient.setQueryData<HarvestTimeEntryResponse>(
      [QueryKeys.HarvestTimeEntries, requestedPayPeriod.beginDate, requestedPayPeriod.endDate],
      (oldHarvestData) => {
        return !oldHarvestData
          ? oldHarvestData
          : {
              ...oldHarvestData,
              time_entries: oldHarvestData.time_entries.filter(
                (entry) => entry.id.toString() !== externalId
              ),
            };
      }
    );
    setEditTimeSheetEntryPending(false);
  };

  const { mutate: addTimeSheetEntriesMutate, isPending: addTimeSheetEntriesPending } = useMutation({
    mutationFn: ({ timeSheetEntries }: AddTimeSheetEntriesV2Props) =>
      addTimeSheetEntriesV2({
        timeSheetEntries: timeSheetEntries,
      }),
    onError: (error) => {
      toast({
        description: error.message,
        title: error.name,
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      toast({
        description: 'Successfully added time sheet entries',
        title: 'Add Time Sheet Entries',
      });
      queryClient.setQueryData<HarvestTimeEntryResponse>(
        [QueryKeys.HarvestTimeEntries, requestedPayPeriod.beginDate, requestedPayPeriod.endDate],
        (oldHarvestData) => {
          return !oldHarvestData
            ? oldHarvestData
            : {
                ...oldHarvestData,
                time_entries: [],
              };
        }
      );
      // Refresh the list of time sheet entries
      queryClient.invalidateQueries({ queryKey: [QueryKeys.TimeSheetPayload, requestedPayPeriod] });
      router.history.push(`/`);
    },
  });

  return (
    <>
      <header className="sticky top-0 z-50 w-full bg-navigation text-navigation-foreground backdrop-blur supports-[backdrop-filter]:bg-navigation-transparent/75">
        <div className="bg-navigation/95 p-2.5">
          <h1 className="mb-1 scroll-m-20 text-center text-xl font-medium tracking-tight sm:text-4xl sm:font-normal">
            Import Time Entries from Harvest
          </h1>
        </div>
        <div className="flex items-center justify-between">
          <PayPeriodSelector className="hidden px-6 sm:flex" />
          <div className="hidden sm:flex">Refresh page to reset</div>
          <div className="py-2 pl-2 pr-1 sm:pl-40 sm:pr-6">
            <Button variant="outline" onClick={() => router.history.push(`/`)}>
              Cancel
            </Button>
            {timeEntries.some((entry) => entry.usesDefaultProjectOrTask) ? (
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button className="ml-2">Submit Entries</Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                      Some entries still use the default mapping. Are you sure you want to submit
                      now?
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => addTimeSheetEntriesMutate({ timeSheetEntries: timeEntries })}
                    >
                      Submit
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            ) : (
              <Button
                className="ml-2"
                disabled={timeEntries.length === 0}
                onClick={() => addTimeSheetEntriesMutate({ timeSheetEntries: timeEntries })}
              >
                Submit Entries
              </Button>
            )}
          </div>
        </div>
      </header>
      {harvestIsLoading || payloadIsLoading || mappingsAreLoading || addTimeSheetEntriesPending ? (
        <EgSpinner />
      ) : timeEntries.length === 0 ? (
        <div className="ml-2 mt-2">No Harvest time entries are available to submit.</div>
      ) : (
        <div className="container mx-auto pb-12 sm:py-10">
          <DataTable
            columns={getExternalTimeEntryColumns(
              removeHarvestEntry,
              'Harvest',
              saveAndDefaultRelatedHarvestEntries,
              projects,
              saveHarvestEntry,
              editTimeSheetEntryPending
            )}
            data={timeEntries}
          />
        </div>
      )}
      <footer className="fixed bottom-0 w-full border-t bg-navigation-transparent/75 text-navigation-foreground backdrop-blur sm:hidden">
        <PayPeriodSelector className="mx-auto" />
      </footer>
    </>
  );
};
