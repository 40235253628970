import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
  RedirectRequest,
} from '@azure/msal-browser';

// MSAL configuration
export const msalConfig: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_AZURE_AD_TENANT_ID}`,
    clientId: import.meta.env.VITE_AZURE_AD_CLIENT_ID,
    redirectUri: import.meta.env.VITE_AZURE_AD_REDIRECT_URI || import.meta.env.BASE_URL,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // Log the errors and warnings in all environments
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
        // If we're in local development, be more verbose with the MSAL logging
        if (import.meta.env.DEV) {
          switch (level) {
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
          }
        }
      },
    },
  },
};

export const loginRequest: RedirectRequest = {
  scopes: [import.meta.env.VITE_AZURE_AD_SCOPE],
};

export const graphLoginRequest: RedirectRequest = {
  scopes: [import.meta.env.VITE_GRAPH_API_SCOPE],
};
