import { TimeSheetPayload } from '@/types/TimeSheetPayload.ts';
import { queryApi } from '@/utility/queryApi';

export type GetTimeSheetPayloadProps = {
  dateInPayPeriod: Date;
};

export const getTimeSheetPayload = ({ dateInPayPeriod }: GetTimeSheetPayloadProps) =>
  queryApi<TimeSheetPayload>({
    method: 'GET',
    path: `/api/user/time-sheet-payload/${dateInPayPeriod.toISOString()}`,
  });
