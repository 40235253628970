import { Cross1Icon } from '@radix-ui/react-icons';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { ExternalTimeSheetSource } from '@/types/ExternalTimeSheetMapping';

type DeleteExternalTimeEntryButtonProps = {
  deleteEntry: (externalId: string) => void;
  externalId: string;
  externalTimeSheetSource: ExternalTimeSheetSource;
};

export const DeleteExternalTimeEntryButton = ({
  deleteEntry,
  externalId,
  externalTimeSheetSource,
}: DeleteExternalTimeEntryButtonProps) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="destructive" title="Delete">
          <Cross1Icon></Cross1Icon>
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            {`Are you sure you want to remove this ${externalTimeSheetSource === 'Harvest' ? 'Harvest' : 'Outlook'} time entry? You can always refresh the page to get it back.`}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            variant="destructive"
            type="submit"
            onClick={() => deleteEntry(externalId)}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
