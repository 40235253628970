// Using ?react so vite-plugin-svgr will include it (it requires the ?react by default in v4 of the plugin)
import EgLogo from '../assets/eg.svg?react';

export const EgSpinner = () => {
  return (
    <div className="flex flex-1 items-center justify-center">
      <EgLogo className="h-12 w-12 animate-gear fill-primary motion-reduce:transition-none" />
    </div>
  );
};
